const SearchSentences = ['부동산 투자로 수익내기',
  '초보자를 위해 주식 시장 파헤치기',
  '전자책 셀프 출판해 베스트셀러 만들기',
  '창의 혁신으로 비즈니스 성공하기',
  '여성 리더가 되기 위한 지침',
  '약초의 신비한 효능',
  '우뇌의 힘으로 비즈니스 구축하기',
  '불편한 대화를 나누는 기술',
  '웹 글쓰기로 수익 노리기',
  '불확실한 경제에서 살아 남으려면',
  '사회적 공감 능력이 부족한 사람들',
  '감정 다스려 더 나은 삶을 살기',
  '1분에 배우는 심리 기법',
  '자신을 사랑하는 법 배우기',
  '재난에서 생존하는 노하우',
  '휴대폰 끊고 사는 방법',
  '문제를 해결하는 좋은 사고법',
  '유전자는 나를 얼마나 결정하는가',
  '심리 게임이 성공할까',
  '판매 전략의 핵심은 무엇일까',
  '지나친 생각은 해롭다는데',
  '남자의 뇌에는 대체 뭐가 있을까',
  '간결하게 말하기가 필요하다',
  '논쟁에게 감사하라',
  '브랜드를 세뇌시켜라',
  '멘탈이 흔들려요',
  '가면증후군에서 벗어나세요',
  '생각의 함정에 빠지지 않는 법',
  '자가면역 질환 이겨냅시다',
  '높은 성취를 위한 자기관리 전략',
  '여성의 뇌가 궁금하다',
  '불안감을 해소하는 법',
  '하루를 유익하게 보내기',
  '습관 부수고 습관 만들기',
  '스타트업의 성공 전략은',
  '한국에서 계속 살아도 될까',
  '인공지능 시대 전문직의 미래는',
  '늙지 않는 동안의 비법',
  '가공식품은 얼마나 나쁜가',
  '망상이 몸을 지배하면 안 되요',
  '스트레스 날려 버리기',
  '마음의 병을 다스리는 법',
  '긍정적 감정이 주는 힘',
  '시간 관리로 성취와 평온을',
  '미래의 위협이 궁금하다',
  '정리하고 비우는 삶으로 돌아가기',
  '명상으로 마음의 평화를',
  '수면 부족을 어떻게 해결하나',
  '친구를 만들고 우정을 유지하는 비결',
  '가스라이팅을 퇴치하는 처방',
  '나쁜 남자 집착의 출구 전략',
  '스포츠 심리학으로 성과 향상하기',
  '부자가 되는 최고의 방법',
  '채소 자연식이 정말 좋을까',
  '죽음에 대해 알아두어야 할 것들',
  '고객을 매료시키는 판매 전략',
  '재정을 알아야 성공한다',
  '성격 유형을 아시나요',
  '창조성을 높이는 사고 방식',
  '건강하게 장수하려면',
  '직장에서 업무 효율 극대화 하기',
  '부자 아빠의 투자 비법',
  '감정적 갈등 피하는 법',
  '다이어트 제대로 해보자',
  '대화로 인간관계 성공하는 법',
  '사이코패스 식별하기',
  '효과적인 학습 방법이 있나',
  '올바른 투자를 막는 방해물',
  '음식에 중독 되셨나요',
  '실리콘밸리 혁신의 비밀이 궁금하다',
  '인공지능이 가져올 실업',
  '가난에서 벗어나려면',
  '행복에 관한 오해들',
  '스토리텔링으로 설득하라',
  '코로나 이후의 세상은 어떨까',
  '디자인을 잘 하는 비결',
  '좋은 운 낚아채는 법',
  '협상을 통한 이익 얻기',
  '커플이 위기에 처할 때',
  '마약이란 어떤 것인가',
  '기후 변화가 미칠 앞으로의 영향은',
  '고뇌하는 리더를 위한 지침',
  '직장에서 생존하는 요령',
  '지금 가족과 살고 있나요',
  '재능을 어떻게 하면 살릴 수 있나',
  '비타민 솔루션이 궁금하다',
  '나르시시스트에 대처하는 현명한 방법',
  '결혼을 하면 뭐가 좋은가',
  '나의 애착 유형은',
  '하나님의 사랑에 의지해 볼까',
  '환각을 일으키는 물질의 비밀',
  '알레르기를 퇴치하는 음식',
  '자본주의가 망한다구요',
  '알파 남성의 실체는',
  '권력을 얻는 최고의 방법',
  '절약을 통해 부자가 되는 법',
  '인간, 이 정도는 알고 있어야',
  '훌륭한 관리자는 어떤 모습일까',
  '몰입으로 성취 극대화 하기',
  '성관계가 자꾸 생각난다',
  '마케팅 대가의 은밀한 기술',
  '시스템 사고로 투자하라',
  '암호화폐로 돈 버는 법',
  '현대에 만연한 정신병의 실체',
  '리더십의 단순한 진실',
  '포퓰리즘 정치인 가려내기',
  '음악으로 내 마음을 치료하자',
  '어떻게 최고의 제품을 만드는가',
  '의지력 강화의 비법',
  '실패하는 사람의 특징',
  '이혼? 그게 쉽냐고요',
  '샤머니즘, 영혼, 회복',
  '긍정이 지나치면 문제다',
  '완벽이라는 강박에 빠진 사람들',
  '영향력 있는 사람들의 특징',
  '빌런은 이렇게 공략하라',
  '의사소통 달인에게 물어보자',
  '연애는 어떻게 하는 걸까',
  '경청하면 무조건 달성한다',
  '돈, 어떻게 벌어야 하나',
  '내 사업 키우는 방법',
  '통계로 사기치는 인간들을 조심해',
  '두뇌의 능력을 키우자',
  '불쌍한 밀레니얼 좀 이해해 주세요',
  '프레임을 알면 그를 이길 수 있다',
  '우울증 이겨낼 수 있을까요',
  '중독에서 벗어나지 못하는 나',
  '누가 폭력 성향을 가지고 있을까',
  '헛소리에 속아 넘어가지 않는 법',
  '이력서 작성과 취업 꿀팁',
  '외로움은 나를 조금씩 갉아 먹는다',
  '목표를 세우고 나아가는 법',
  '단식, 정말 해 본 적 없나요',
  '천재들의 은밀한 습관',
  '프리랜서로 돈 버는 다양한 방법',
  '전략의 신이 되어보자',
  '뛰어난 아이디어는 어떻게 나오는가',
  '겸손이 정말 성공을 가져다 줄까',
  '전염병은 또 오는가',
  '비합리적 결정을 내리는 나',
  '학습하는 자가 살아남는다',
  '재능만으로 성공이 되나',
  '사랑하고 싶어 안달난 이들',
  '매력적인 남성의 조건',
  '당신의 뇌가 똑똑하다고 생각하는가',
  '거짓말은 과연 얼마나 통할까',
  '홍보를 잘 하는 자가 결국 이긴다',
  '고통을 견디고 이기는 묘법',
  '관상 좀 볼 줄 아시는가'];
export default SearchSentences;
export const SearchSentencesEn = [
  'Making money from real estate investment',
  'Digging into the stock market for a beginner',
  'Self-publish an ebook and make it a bestseller',
  'Winning business with creative innovation',
  'Guideline for becoming a female leader',
  'The mysterious power of herb',
  'Build business with the power of your right brain',
  'The art of uncomfortable conversation',
  'Earn money with web writing',
  'To survive in an uncertain economy',
  'People who lack social empathy',
  'Manage your emotion to live a better life',
  'Psychological technique in one minute',
  'Learning to love yourself',
  'How to survive a disaster',
  'How to live without a mobile phone',
  'Good problem solving thinking',
  'How much do my gene determine me',
  'Will the mind game work',
  "What's at the core of your sales strategy",
  'They say overthinking is harmful',
  'What is in the brain of man',
  'You need to be concise',
  'Be grateful for argument',
  'Brainwash your brand',
  "I'm mentally shaken",
  'Break free from mask syndrome',
  'How to avoid falling into thinking trap',
  'Overcoming autoimmune disease',
  'Self-care strategy for high performance',
  'Curious about the female brain',
  'How to deal with anxiety',
  'Make the most of your day',
  'Breaking habit and creating habit',
  'A successful startup strategy',
  'Where is the best place to live',
  'The future of the profession in the age of AI',
  'Secret to never getting old',
  'How bad is processed food',
  "Don't let delusion control your body",
  'Letting go of stress',
  'How to deal with mental illness',
  'The power of positive emotion',
  'Time management for achievement and serenity',
  'Wondering about future threat',
  'Return to a life of decluttering and emptying',
  'Meditation for peace of mind',
  'How to deal with sleep deprivation',
  'Secret to making and keeping friend',
  'Prescription to combat gaslighting',
  'Exit strategy for bad boy obsession',
  'Improving performance with sports psychology',
  'The best way to get rich',
  'Is a raw veggie diet really good for you',
  'Things to know about death',
  'Sales strategy that engage customer',
  'Know your finance to succeed',
  'Do you know your personality type',
  'Mindset that inspire creativity',
  'To live a long and healthy life',
  'Maximise your efficiency at work',
  'Investing secret from a rich dad',
  'How to avoid emotional conflict',
  'Get your diet right',
  'How to win with conversation',
  'Identifying a psychopath',
  'Is there an effective way to learn',
  'Obstacle to making the right investment',
  'Addicted to food',
  'The secret of Silicon Valley innovation',
  'Artificial intelligence will bring unemployment',
  'Why are you poor if you work hard',
  'Myth about happiness',
  'Tell a story to persuade',
  'What will the post-COVID world look like',
  'The secret to good design',
  'How to catch good luck',
  'Negotiate to your advantage',
  'When a couple is in crisis',
  'What is a drug',
  'The future impact of climate change',
  'Guidance for an anguished leader',
  'Tip to survive at work',
  'Do you live with your family now',
  'How to harness your talent',
  'Curious about the vitamin solution',
  'Smart ways to deal with a narcissist',
  "What's good about marriage",
  'My attachment type is',
  'Relying on thd love of God',
  'Secret of substance that causes hallucination',
  'Food that fight allergy',
  'Capitalism is fucked',
  'The alpha male entity is',
  'The best way to gain power',
  'How to get rich by saving',
  'Human, you should know this much',
  'What a great manager looks like',
  'Maximise achievement with immersion',
  "I can't stop thinking about sex",
  'Secret art of the marketing mastermind',
  'Invest with system thinking',
  'How to make money with cryptocurrency',
  'The reality of modern mental illness',
  'The simple truth of leadership',
  'Identify a populist politician',
  'Let music heal my heart',
  'How to build the best product',
  'Tip for building willpower',
  'Characteristic of people who fail',
  'Divorce? Is that easy?',
  'Shamanism, spirit, and recovery',
  'Too much positivity is a problem',
  'People who are obsessed with perfection',
  'Characteristic of influential people',
  'This is how you should approach a villain',
  'Ask a communication master',
  'How do you make love',
  'When you listen, you achieve',
  'Money, how to make it',
  'How to grow my business',
  'Beware of people who scam you with statistics',
  'Build your brain power',
  'Pity the poor millennial',
  'Know the frame and you can beat him',
  'Can depression be beaten',
  "I can't get over my addiction",
  'Who has a propensity for violence',
  'How to avoid being fooled by bullshit',
  'Resume writing and job hunting tip',
  'Loneliness gnaws at me little by little',
  'How to set a goal and move forward',
  'Fasting, have you really never done it',
  'Secret habit of a genius',
  'Different ways to make money as a freelancer',
  'Become a strategy god',
  'How to come up with a great idea',
  'Does humility really bring success',
  'Is there another pandemic coming',
  'Me making an irrational decision',
  'Those who learn survive',
  'Does talent alone make you successful',
  'Desperate for love',
  'What makes an attractive man',
  'Do you think your brain is smart',
  'How well does a lie work',
  'He who promote well win in the end',
  'How to endure pain and win',
  'Are you a contemplative person',
];
export const SearchSentencesZh = [
  '从房地产投资中赚钱',
  '为初学者挖掘股市商机',
  '自费出版电子书并使其成为畅销书',
  '以创意创新赢得业务',
  '成为女性领导者的指南',
  '草药的神秘力量',
  '用右脑的力量创建业务',
  '不自在谈话的艺术',
  '通过网络写作赚钱',
  '在不确定的经济环境中生存',
  '缺乏社会同情心的人',
  '管理情绪，让生活更美好',
  '一分钟掌握心理技巧',
  '学会爱自己',
  '如何在灾难中生存',
  '没有手机如何生活',
  '良好的问题解决思维',
  '我的基因在多大程度上决定了我',
  '智力游戏是否有效',
  '销售战略的核心是什么',
  '他们说过度思考是有害的',
  '人的大脑里有什么',
  '你需要简明扼要',
  '感谢争论',
  '为品牌洗脑',
  '我精神动摇了',
  '摆脱面具综合症',
  '如何避免陷入思维陷阱',
  '克服自身免疫疾病',
  '高绩效的自我保健策略',
  '对女性大脑充满好奇',
  '如何应对焦虑',
  '充分利用每一天',
  '打破习惯，创造习惯',
  '成功的创业战略',
  '哪里最适合居住',
  '人工智能时代的职业未来',
  '永不变老的秘诀',
  '加工食品有多糟糕',
  '别让妄想控制你的身体',
  '释放压力',
  '如何应对精神疾病',
  '积极情绪的力量',
  '时间管理，成就与宁静',
  '思考未来的威胁',
  '回归整理和清空的生活',
  '让心灵平静的冥想',
  '如何应对睡眠不足',
  '结交和留住朋友的秘诀',
  '对抗煤气灯的处方',
  '坏男孩迷恋的退出策略',
  '用运动心理学提高成绩',
  '致富的最佳途径',
  '生食蔬菜真的对身体有益吗',
  '死亡须知',
  '吸引客户的销售策略',
  '了解你的财务状况才能取得成功',
  '你了解自己的性格类型吗',
  '激发创造力的心态',
  '健康长寿',
  '最大限度地提高工作效率',
  '富爸爸的投资秘诀',
  '如何避免情感冲突',
  '正确饮食',
  '如何以谈话取胜',
  '识别心理变态者',
  '是否有有效的学习方法',
  '正确投资的障碍',
  '对食物上瘾',
  '硅谷创新的秘密',
  '人工智能将带来失业',
  '努力工作为什么会贫穷',
  '关于幸福的神话',
  '用故事说服人',
  '后科维德世界会是什么样子',
  '优秀设计的秘诀',
  '如何抓住好运',
  '有利的谈判',
  '当一对夫妇陷入危机时',
  '什么是毒品',
  '气候变化对未来的影响',
  '为苦恼的领导者提供指导',
  '工作中的生存技巧',
  '你现在是否与家人住在一起',
  '如何发挥您的才能',
  '对维生素解决方案的好奇',
  '对付自恋狂的聪明方法',
  '婚姻的好处',
  '我的依恋类型是',
  '依赖上帝的爱',
  '导致幻觉的物质的秘密',
  '抗过敏食物',
  '资本主义完蛋了',
  '阿尔法男性实体是',
  '获得权力的最佳途径',
  '如何通过储蓄致富',
  '人类，你需要知道的很多',
  '伟大的管理者是什么样的',
  '通过沉浸式学习获得最大成就',
  '我无法停止对性的思考',
  '营销大师的秘密艺术',
  '用系统思维进行投资',
  '如何利用加密货币赚钱',
  '现代精神疾病的现实',
  '领导力的简单真相',
  '识别民粹主义政治家',
  '让音乐治愈我的心灵',
  '如何打造最好的产品',
  '培养意志力的技巧',
  '失败者的特征',
  '离婚？离婚容易吗',
  '萨满教、精神和康复',
  '过于积极是个问题',
  '追求完美的人',
  '有影响力的人的特征',
  '如何与坏人打交道',
  '向沟通大师请教',
  '如何制造爱',
  '当你倾听时，你就成功了',
  '如何赚钱',
  '如何发展我的业务',
  '小心那些用统计数据骗你的人',
  '增强你的脑力',
  '可怜的千禧一代',
  '了解框架，你就能打败他',
  '抑郁症可以战胜吗',
  '我无法摆脱毒瘾',
  '谁有暴力倾向',
  '如何避免上当受骗',
  '简历撰写和求职技巧',
  '孤独一点一点地啃噬着我',
  '如何设定目标并勇往直前',
  '断食，你真的没做过吗',
  '天才的秘密习惯',
  '自由职业者的各种赚钱方法',
  '成为战略之神',
  '如何想出好点子',
  '谦虚真的会带来成功吗',
  '是否会有另一场大流行',
  '我做出了一个非理性的决定',
  '学习者生存',
  '仅凭天赋就能成功吗',
  '渴望被爱的人',
  '怎样的男人才有魅力',
  '你认为自己的大脑聪明吗',
  '谎言的效果如何',
  '善于宣传的人最终会赢',
  '如何忍受痛苦并取得胜利',
  '你能读懂别人的表情吗',
];
export const SearchSentencesJa = [
  '不動産投資で利益を出す',
  '株式市場の初心者ガイド',
  '電子書籍でベストセラーを作る',
  'イノベーションでビジネス成功',
  '女性リーダーのための指針',
  '薬草の不思議な効能',
  '右脳でビジネスを構築',
  '不快な会話のテクニック',
  'ウェブライティングで収益狙う',
  '不確実な経済で生き抜く',
  '社会的共感能力の不足',
  '感情コントロールと良い人生',
  '1分で学ぶ心理テクニック',
  '自己愛を学ぶ',
  '災害で生き残るノウハウ',
  '携帯電話なしで生きる方法',
  '問題解決のための思考法',
  '遺伝子が私をどれだけ決定か',
  '心理ゲームは成功するか',
  '販売戦略の核心',
  '過度の思考は有害',
  '男の脳の中身は？',
  '簡潔に話すことが必要',
  '議論に感謝しましょう',
  'ブランドを洗脳せよ',
  '私の精神力が揺らいでいます',
  'マスク症候群からの脱出',
  '思考の罠に陥らない方法',
  '自己免疫疾患を克服しよう',
  '高成果のための自己管理戦略',
  '女性の脳が気になる',
  '不安感を解消する方法',
  '一日を有意義に過ごす',
  '習慣を壊して習慣を作る',
  'スタートアップの成功戦略は',
  'どこに住むのが最も良いでしょうか',
  '人工知能時代の専門職の未来は',
  '歳をとらないための秘訣',
  '加工食品はいかに悪いか',
  '妄想が体を支配してはいけません',
  'ストレスを吹き飛ばす',
  '心の病を治す方法',
  'ポジティブな感情が与える力',
  '時間管理で達成と平穏を',
  '未来の脅威が気になる',
  '整理整頓して空っぽの生活に戻る',
  '瞑想で心の安らぎを得る',
  '睡眠不足を解決する方法',
  '友達を作り、友情を維持する秘訣',
  'ガスライティングを退治する処方箋',
  '悪い男の執着からの脱出策は',
  'スポーツ心理学でパフォーマンス向上',
  '金持ちになるための最良の方法',
  '野菜の自然食は本当に良いのか',
  '死について知っておくべきこと',
  '顧客を魅了する販売戦略',
  '財務を知ることで成功する',
  'あなたの性格タイプをご存知ですか',
  '創造性を高める考え方',
  '健康的に長生きするには',
  '仕事で仕事の効率を最大化する',
  '金持ち父さんの投資の秘訣',
  '感情的な葛藤を避ける方法',
  'ダイエットを正しくやってみよう',
  '会話で人間関係を成功させる方法',
  'サイコパスの見分け方',
  '効果的な学習方法はあるか',
  '正しい投資を妨げるもの',
  '食べ物にはまっていませんか',
  'シリコンバレーのイノベーション秘密',
  '人工知能がもたらす失業',
  '一生懸命働いているのに、なぜ貧しいのか',
  '幸福に関する誤解',
  'ストーリーテリングで説得する',
  'コロナ後の世界はどうなるのか',
  'デザインがうまくいく秘訣',
  '良い運をつかむ方法',
  '交渉で利益を得る',
  'カップルが危機に陥ったとき',
  '麻薬とはどのようなものか',
  '気候変動がもたらす今後の影響とは',
  '苦悩するリーダーのための指針',
  '職場で生き残るためのヒント',
  '家族と一緒に住んでいますか',
  '才能を活かすにはどうすればいいのか',
  'ビタミンの解決策が気になる',
  'ナルシシストに対処する賢い方法',
  '結婚すると何がいいのか',
  '私の愛着のタイプは',
  '神の愛に頼ってみよう',
  '幻覚を引き起こす物質の秘密',
  'アレルギーを退治する食べ物',
  '資本主義は破綻する',
  'アルファ男性の正体は',
  '権力を手に入れる最良の方法',
  '節約で金持ちになる方法',
  '人についてこれくらいは知っておくべき',
  '優れた経営者とはどのようなものか',
  '没頭することで達成を最大化する',
  'セックスのことが頭から離れない',
  'マーケティングの達人の秘密の技術',
  'システム思考で投資する',
  '仮想通貨でお金を稼ぐ方法',
  '現代に蔓延する精神病の実態',
  'リーダーシップのシンプルな真実',
  'ポピュリズム政治家の見分け方',
  '音楽で自分の心を癒そう',
  'どのように最高の製品を作るか',
  '意志力強化の秘訣',
  '失敗する人の特徴',
  '離婚, それは簡単ですか',
  'シャーマニズム、スピリット、回復',
  '肯定的すぎるのは問題だ',
  '完璧という強迫観念にとらわれる人たち',
  '影響力のある人の特徴',
  'ヴィランはこう攻めろ',
  'コミュニケーションの達人に聞いてみよう',
  '恋愛はどうすればいいのか',
  '傾聴すれば無条件に達成する',
  'お金、どうやって稼げばいいのか',
  '自分のビジネスを育てる方法',
  '統計で騙す人間に気をつけろ',
  '脳の能力を育てよう',
  '哀れなミレニアル世代を理解してください',
  'フレームを知れば彼を打ち負かせる',
  'うつ病を乗り越えられるか',
  '依存症から抜け出せない私',
  '誰が暴力的な傾向を持っているのか',
  '欺瞞に騙されないようにする方法',
  '履歴書の書き方と就職のコツ',
  '孤独は私をじわじわと蝕んでいる',
  '目標を立てて進む方法',
  '断食、本当にやったことないですか',
  '天才たちの秘密の習慣',
  'フリーランスでお金を稼ぐ様々な方法',
  '戦略の神になろう',
  '優れたアイデアはどうやって生まれるのか',
  '謙虚さが本当に成功をもたらすのか',
  'パンデミックはまた来るのか',
  '非合理的な決断をする私',
  '学習する者が生き残る',
  '才能だけで成功するのか',
  '愛したくてたまらない人たち',
  '魅力的な男性の条件',
  'あなたの脳は賢いと思っているのか',
  '嘘は果たしてどれだけ通用するのか',
  '広報が上手い者が結局勝つ',
  '痛みに耐えて勝つコツ',
  '顔が何を教えてくれるか知っていますか',
];
export const SearchSentencesEs = [
  'Ganar dinero con inversiones inmobiliarias',
  'Explorar la bolsa para novatos',
  'Autopublicar un ebook y hacerlo bestseller',
  'Triunfar en negocios con innovación creativa',
  'Guía para ser una líder femenina',
  'El poder misterioso de las hierbas',
  'Construir negocios con el cerebro derecho',
  'Manejar conversaciones difíciles',
  'Ganar escribiendo online',
  'Sobrevivir en economía volátil',
  'La falta de empatía social',
  'Controlar emociones para una vida mejor',
  'Aprender psicología en un minuto',
  'Aprender a quererse',
  'Sobrevivir desastres',
  'Vivir sin móvil',
  'Pensar bien para resolver problemas',
  'El impacto de los genes en ti',
  '¿Funcionan los juegos mentales?',
  'Claves de una estrategia de ventas',
  'Los daños de pensar demasiado',
  '¿Qué contiene el cerebro masculino',
  'Importancia de la concisión',
  'Valorar el debate',
  'Influenciar tu marca',
  'Estabilidad mental fluctuante',
  'Escapar del síndrome del impostor',
  'Evitar trampas de pensamiento',
  'Vencer enfermedades autoinmunes',
  'Autocuidado para alto rendimiento',
  'Curiosidades del cerebro femenino',
  'Cómo aliviar la ansiedad',
  'Aprovechar al máximo el día',
  'Romper hábitos y crear costumbres',
  'Cómo ser una startup exitosa',
  '¿Dónde es el mejor lugar para vivir',
  'Profesionalidad en la era AI',
  'El secreto para no envejecer',
  '¿Qué tan mala es la comida procesada',
  'No dejes que las ilusiones te dominen',
  'Liberarse del estrés',
  'Afrontar las enfermedades mentales',
  'El poder de las emociones positivas',
  'Gestión del tiempo para la calma',
  'Preocuparse por amenazas futuras',
  'Volver a una vida ordenada',
  'Paz mental con meditación',
  'Cómo afrontar la falta de sueño',
  'Secretos de la amistad duradera',
  'Receta contra el gaslighting',
  'Estrategia de salida para obsesiones',
  'Rendimiento y psicología deportiva',
  'La mejor forma de hacerse rico',
  '¿Es buena la dieta vegetariana cruda',
  'Qué saber sobre la muerte',
  'Estrategias de venta para atraer clientes',
  'Conoce tus finanzas para el éxito',
  '¿Conoces tu tipo de personalidad',
  'Mentalidades que potencian la creatividad',
  'Vivir largo y saludable',
  'Maximizar eficacia laboral',
  'Secretos de inversión de Padre Rico',
  'Evitar conflictos emocionales',
  'Cómo hacer una dieta adecuada',
  'Éxito en relaciones por diálogo',
  'Identificar a un psicópata',
  '¿Métodos de aprendizaje eficaces',
  'Obstáculos en inversiones adecuadas',
  '¿Adicto a la comida?',
  'Innovación de Silicon Valley',
  'Desempleo por inteligencia artificial',
  '¿Pobre a pesar de trabajar duro',
  'Conceptos erróneos sobre felicidad',
  'Persuadir contando historias',
  'Mundo post-COVID',
  'El secreto del buen diseño',
  'Atraer buena suerte',
  'Negociar con ventaja',
  'Cuando las parejas están en crisis',
  '¿Cómo son las drogas',
  'Impactos del cambio climático',
  'Pautas para líderes en apuros',
  'Sobrevivir en el trabajo',
  '¿Vives con tu familia',
  'Mantener vivos tus talentos',
  'La solución vitamínica',
  'Tratar con narcisistas inteligentemente',
  'Beneficios del matrimonio',
  'Mi tipo de apego',
  'Confiar en el amor de Dios',
  'Secretos de sustancias alucinógenas',
  'Alimentos contra alergias',
  '¿El capitalismo condenado',
  'Realidad de los machos alfa',
  'Mejor forma de ganar poder',
  'Enriquecerse con frugalidad',
  'Conocimientos esenciales sobre personas',
  '¿Cómo es un gran directivo',
  'Maximizar logros por inmersión',
  'Pensamientos constantes sobre sexo',
  'Arte secreto del marketing',
  'Invertir con pensamiento sistémico',
  'Ganancias con criptodivisas',
  'Naturaleza de enfermedades mentales modernas',
  'Verdades del liderazgo',
  'Detectar políticos populistas',
  'Curar el corazón con música',
  'Crear el mejor producto',
  'Fortalecer la fuerza de voluntad',
  'Características de quien fracasa',
  '¿Divorcio fácil?',
  'Chamanismo y recuperación',
  'Problema del exceso de positividad',
  'Convertirse en una persona exitosa',
  'Características de personas influyentes',
  'Cómo enfrentar a un villano',
  'Consulta a maestros de comunicación',
  '¿Cómo se hace el amor',
  'Escuchar garantiza el éxito',
  'Dinero, cómo conseguirlo',
  'Crecer mi negocio',
  'Cuidado con engaños estadísticos',
  'Aumentar la capacidad cerebral',
  'Compadécete del millennial',
  'Si conoces el marco, ganarás',
  '¿Puedo vencer la depresión',
  'No supero mi adicción',
  'Quién tiene tendencias violentas',
  'Evitar caer en tonterías',
  'Consejos para redactar el CV',
  'La soledad me consume',
  'Cómo marcarse objetivos',
  'Ayunar, ¿nunca lo has hecho',
  'Hábitos secretos de genios',
  'Ganar dinero como autónomo',
  'Ser un dios de la estrategia',
  '¿Cómo surgen las grandes ideas',
  '¿La humildad trae éxito',
  '¿Regresa la pandemia',
  'Tomo decisiones irracionales',
  'El que aprende sobrevive',
  '¿El éxito solo con talento',
  'Desesperados por ser amados',
  'Qué hace a un hombre atractivo',
  '¿Tu cerebro es inteligente',
  '¿Funcionan las mentiras',
  'Ganar con buena publicidad',
  'Arte de soportar dolor y ganar',
  '¿Qué revela el rostro',
];
export const SearchSentencesVi = [
  'Kiếm lợi nhuận từ bất động sản',
  'Khám phá thị trường chứng khoán',
  'Tự xuất bản ebook thành bestseller',
  'Thành công kinh doanh bằng sáng tạo',
  'Hướng dẫn trở thành nữ lãnh đạo',
  'Công dụng kỳ diệu của thảo mộc',
  'Xây dựng doanh nghiệp với bán cầu não',
  'Duy trì cuộc đối thoại khó khăn',
  'Kiếm lợi từ việc viết web',
  'Sống sót trong kinh tế bất ổn',
  'Thiếu khả năng cảm thông xã hội',
  'Quản lý cảm xúc sống tốt hơn',
  'Học tâm lý trong một phút',
  'Học cách yêu bản thân',
  'Sống sót trong thảm họa',
  'Sống không dùng điện thoại di động',
  'Suy nghĩ hiệu quả giải quyết vấn đề',
  'Gen quyết định tôi thế nào',
  'Trò chơi tâm lý có thành công không',
  'Bản chất chiến lược bán hàng',
  'Suy nghĩ quá nhiều có hại',
  'Não của đàn ông có gì',
  'Cần nói gọn lại',
  'Biết ơn cuộc tranh luận',
  'Thao túng thương hiệu',
  'Sức mạnh tinh thần dao động',
  'Thoát hội chứng kẻ mạo danh',
  'Không rơi vào bẫy suy nghĩ',
  'Chiến thắng bệnh tự miễn',
  'Chiến lược tự quản lý cho thành tựu',
  'Não phụ nữ thật thú vị',
  'Giải tỏa lo lắng',
  'Ngày bổ ích',
  'Phá vỡ và tạo thói quen mới',
  'Chiến lược thành công cho startup',
  'Nơi tốt nhất để sống',
  'Tương lai nghề nghiệp trong kỷ nguyên AI',
  'Bí quyết giữ gìn thanh xuân',
  'Thực phẩm chế biến có hại như thế nào',
  'Không để ảo tưởng kiểm soát',
  'Xua tan căng thẳng',
  'Điều trị bệnh tâm lý',
  'Sức mạnh của cảm xúc tích cực',
  'Quản lý thời gian cho thành tựu và bình yên',
  'Mối đe dọa trong tương lai',
  'Cuộc sống đơn giản và gọn gàng',
  'Bình yên tâm trí qua thiền định',
  'Giải quyết vấn đề thiếu ngủ',
  'Bí quyết kết bạn và duy trì tình bạn',
  'Đơn thuốc chống gaslighting',
  'Lối thoát khi ám ảnh với người xấu',
  'Hiệu suất cao qua tâm lý học thể thao',
  'Cách trở nên giàu có',
  'Chế độ ăn toàn rau củ có tốt không',
  'Những điều cần biết về cái chết',
  'Chiến lược bán hàng hấp dẫn',
  'Hiểu biết tài chính và thành công',
  'Các loại tính cách',
  'Tư duy sáng tạo nâng cao sự sáng tạo',
  'Muốn sống lâu sống khỏe',
  'Hiệu quả công việc tối đa',
  'Bí quyết đầu tư của bố giàu',
  'Tránh xung đột cảm xúc',
  'Làm chế độ ăn kiêng đúng đắn',
  'Thành công qua đối thoại',
  'Nhận biết kẻ tâm thần phân liệt',
  'Phương pháp học tập hiệu quả',
  'Trở ngại cho đầu tư đúng đắn',
  'Bạn có nghiện thức ăn không',
  'Bí mật đổi mới Silicon Valley',
  'Thất nghiệp do trí tuệ nhân tạo',
  'Tại sao chăm chỉ mà vẫn nghèo',
  'Hiểu lầm về hạnh phúc',
  'Thuyết phục bằng câu chuyện',
  'Thế giới sau đại dịch Corona',
  'Bí quyết thiết kế giỏi',
  'Cách bắt may mắn',
  'Thu lợi từ đàm phán',
  'Khi cặp đôi gặp khủng hoảng',
  'Ma túy là gì',
  'Ảnh hưởng biến đổi khí hậu',
  'Hướng dẫn cho lãnh đạo suy tư',
  'Kỹ năng sống sót trong công sở',
  'Bạn có sống cùng gia đình không',
  'Phát huy tài năng',
  'Giải pháp vitamin',
  'Đối phó với người tự yêu',
  'Lợi ích của việc kết hôn',
  'Loại tình cảm gắn bó của bạn',
  'Dựa vào tình yêu Chúa',
  'Bí mật chất gây ảo giác',
  'Thực phẩm chống dị ứng',
  'Chủ nghĩa tư bản sụp đổ',
  'Bản chất đàn ông Alpha',
  'Phương pháp đạt quyền lực tốt nhất',
  'Làm giàu bằng cách tiết kiệm',
  'Bạn nên biết điều này về mọi người',
  'Hình mẫu người quản lý giỏi',
  'Tối đa hóa thành tựu qua tập trung',
  'Luôn nghĩ về quan hệ tình dục',
  'Kỹ thuật bí mật của chuyên gia tiếp thị',
  'Đầu tư theo tư duy hệ thống',
  'Kiếm tiền từ tiền điện tử',
  'Bệnh tâm thần phổ biến hiện nay',
  'Sự thật đơn giản về lãnh đạo',
  'Lọc chính trị gia theo chủ nghĩa dân túy',
  'Chữa lành tâm hồn bằng âm nhạc',
  'Làm thế nào tạo sản phẩm tốt nhất',
  'Bí quyết cải thiện sức mạnh ý chí',
  'Đặc điểm người thất bại',
  'Ly hôn có dễ không',
  'Chủ nghĩa Thần linh, Linh hồn, Phục hồi',
  'Vấn đề của sự lạc quan quá mức',
  'Cái bẫy của sự hoàn hảo',
  'Đặc điểm người có ảnh hưởng',
  'Cách tiếp cận kẻ xấu',
  'Hỏi ý kiến chuyên gia giao tiếp',
  'Làm thế nào để yêu',
  'Đạt mục tiêu bằng cách lắng nghe',
  'Làm thế nào để kiếm tiền',
  'Phát triển doanh nghiệp của bạn',
  'Cảnh giác với lừa đảo số liệu thống kê',
  'Phát triển khả năng não bộ',
  'Hiểu thế hệ Millennial',
  'Biết khung để đánh bại đối thủ',
  'Có thể vượt qua trầm cảm không',
  'Không thể thoát khỏi nghiện ngập',
  'Ai có xu hướng bạo lực',
  'Không bị lừa bởi lời nói vô nghĩa',
  'Mẹo viết sơ yếu lý lịch và tìm việc',
  'Cô đơn phá hủy tôi từ từ',
  'Cách đặt mục tiêu và tiến lên',
  'Bạn đã thử nhịn ăn bao giờ chưa',
  'Thói quen bí mật của thiên tài',
  'Kiếm tiền làm freelancer',
  'Trở thành thần chiến lược',
  'Làm thế nào để có ý tưởng xuất sắc',
  'Sự khiêm tốn mang lại thành công không',
  'Dịch bệnh có quay trở lại không',
  'Đưa ra quyết định không hợp lý',
  'Người học hỏi sẽ tồn tại',
  'Chỉ tài năng có thành công không',
  'Người khao khát được yêu',
  'Điều kiện của người đàn ông hấp dẫn',
  'Bộ não của bạn có thông minh không',
  'Lời nói dối có hiệu quả không',
  'Người giỏi quảng bá chiến thắng cuối cùng',
  'Cách chịu đựng và chiến thắng nỗi đau',
  'Bạn biết khuôn mặt tiết lộ gì không',
];
export const SearchSentencesId = [
  'Menghasilkan uang dari properti',
  'Menggali pasar saham untuk pemula',
  'Menerbitkan eBook menjadi bestseller',
  'Menang bisnis dengan inovasi kreatif',
  'Panduan jadi pemimpin wanita',
  'Kekuatan Misterius Herbal',
  'Bangun bisnis dengan otak kanan',
  'Seni percakapan yang tidak nyaman',
  'Menghasilkan uang dengan menulis web',
  'Bertahan dalam ekonomi tidak menentu',
  'Kurang empati sosial',
  'Menguasai emosi untuk hidup lebih baik',
  'Teknik psikologis dalam satu menit',
  'Belajar mencintai diri sendiri',
  'Cara bertahan hidup dari bencana',
  'Hidup tanpa ponsel',
  'Cara berpikir baik untuk memecahkan masalah',
  'Pengaruh gen terhadap identitas Anda',
  'Efektivitas permainan pikiran',
  'Kunci strategi penjualan',
  'Terlalu banyak berpikir berbahaya',
  'Isi otak manusia',
  'Anda harus ringkas',
  'Bersyukur atas perdebatan',
  'Cuci otak merek Anda',
  'Kekuatan mental saya goyah',
  'Bebas dari sindrom topeng',
  'Hindari jebakan pikiran',
  'Mengatasi penyakit autoimun',
  'Strategi perawatan diri untuk prestasi',
  'Tentang otak wanita',
  'Meredakan kecemasan',
  'Manfaatkan hari Anda',
  'Hentikan kebiasaan, ciptakan baru',
  'Sukses dengan startup',
  'Tempat terbaik tinggal',
  'Masa depan profesional di AI',
  'Rahasia agar tidak tua',
  'Seberapa buruk makanan olahan',
  'Jangan biarkan ilusi menguasai',
  'Lepaskan stres',
  'Atasi penyakit mental',
  'Kekuatan emosi positif',
  'Manajemen waktu dan ketenangan',
  'Tantangan di masa depan',
  'Kembali ke kehidupan sederhana',
  'Ketenangan melalui meditasi',
  'Atasi kurang tidur',
  'Rahasia berteman dan mempertahankan',
  'Resep lawan gaslighting',
  'Keluar dari obsesi pria jahat',
  'Tingkatkan performa dengan psikologi',
  'Cara menjadi kaya',
  'Diet sayuran mentah',
  'Apa yang perlu diketahui tentang kematian',
  'Strategi penjualan menarik pelanggan',
  'Kenali keuangan untuk sukses',
  'Tipe kepribadian Anda',
  'Pola pikir kreatif',
  'Agar panjang umur dan sehat',
  'Maksimalkan efektivitas di tempat kerja',
  'Rahasia investasi Ayah Kaya',
  'Hindari konflik emosional',
  'Diet yang benar',
  'Sukses dalam hubungan lewat dialog',
  'Identifikasi seorang psikopat',
  'Metode pembelajaran efektif',
  'Hambatan dalam investasi yang tepat',
  'Apakah Anda kecanduan makanan',
  'Rahasia inovasi Silicon Valley',
  'Pengangguran karena AI',
  'Mengapa miskin meski kerja keras',
  'Kesalahpahaman tentang kebahagiaan',
  'Membujuk dengan dongeng',
  'Dunia pasca-COVID',
  'Rahasia desain yang bagus',
  'Menarik keberuntungan',
  'Bernegosiasi untuk keuntungan',
  'Ketika pasangan krisis',
  'Narkoba itu seperti apa',
  'Dampak perubahan iklim',
  'Pedoman untuk pemimpin tertekan',
  'Kiat-kiat bertahan di tempat kerja',
  'Tinggal bersama keluarga',
  'Menjaga bakat tetap hidup',
  'Solusi vitamin',
  'Cerdas menghadapi yang narsis',
  'Manfaat pernikahan',
  'Tipe keterikatan saya',
  'Bergantung pada cinta kasih Tuhan',
  'Rahasia zat pemicu halusinasi',
  'Makanan anti-alergi',
  'Kapitalisme akan runtuh',
  'Realitas Alfa',
  'Cara terbaik memperoleh kekuasaan',
  'Menjadi kaya lewat hemat',
  'Yang perlu Anda tahu tentang orang',
  'Ciri-ciri manajer hebat',
  'Maksimalkan prestasi dengan imersi',
  'Tak bisa berhenti pikirkan seks',
  'Seni rahasia pemasaran',
  'Berinvestasi dengan pemikiran sistem',
  'Menghasilkan uang dengan kripto',
  'Hakikat Penyakit Mental Modern',
  'Kebenaran Kepemimpinan',
  'Mengenali politisi populis',
  'Musik menyembuhkan hati',
  'Membuat produk terbaik',
  'Menguatkan kemauan Anda',
  'Ciri-ciri orang gagal',
  'Apakah perceraian mudah',
  'Perdukunan dan pemulihan',
  'Masalah dengan afirmasi berlebihan',
  'Menjadi orang sukses',
  'Ciri-ciri orang berpengaruh',
  'Mendekati penjahat',
  'Bertanya pada Ahli Komunikasi',
  'Cara bercinta Anda',
  'Dengarkan dan Anda akan berhasil',
  'Uang, cara mendapatkannya',
  'Cara kembangkan bisnis saya',
  'Hati-hati dengan manipulasi statistik',
  'Tingkatkan kekuatan otak',
  'Kasihan generasi milenial',
  'Kenal bingkai, kalahkan',
  'Dapatkah kalahkan depresi',
  'Tak bisa atasi kecanduan',
  'Siapa yang cenderung kekerasan',
  'Cara hindari tipu daya',
  'Tips CV dan mencari kerja',
  'Kesendirian menggerogoti saya',
  'Tetapkan tujuan, maju',
  'Berpuasa, pernah coba',
  'Kebiasaan rahasia jenius',
  'Cara dapat uang sebagai freelancer',
  'Jadi dewa strategi',
  'Munculnya ide-ide hebat',
  'Apakah kerendahan hati sukses',
  'Apakah pandemi akan kembali',
  'Keputusan irasional saya',
  'Belajar untuk bertahan',
  'Apakah bakat saja cukup',
  'Mereka haus akan cinta',
  'Apa yang bikin pria menarik',
  'Seberapa pintar otak Anda',
  'Keberhasilan kebohongan',
  'Siapa pun dengan publisitas terbaik menang',
  'Seni tanggung rasa sakit',
  'Apakah Anda tahu apa yang wajah ungkapkan',
];
export const SearchSentencesTh = [
  'รายได้จากการลงทุนอสังหาริมทรัพย์',
  'แกะรอยตลาดหุ้นสำหรับมือใหม่',
  'เขียน E-book เองและทำให้เป็นขายดี',
  'บรรลุความสำเร็จในธุรกิจด้วยนวัตกรรมสร้างสรรค์',
  'คู่มือสำหรับผู้หญิงที่อยากเป็นผู้นำ',
  'ประโยชน์มหัศจรรย์ของสมุนไพร',
  'สร้างธุรกิจด้วยพลังของสมองซีกขวา',
  'เทคนิคการสนทนาที่ไม่สบายใจ',
  'สร้างรายได้จากการเขียนบนเว็บ',
  'รอดพ้นจากเศรษฐกิจที่ไม่แน่นอน',
  'ผู้ที่ขาดทักษะการเห็นอกเห็นใจทางสังคม',
  'จัดการอารมณ์เพื่อชีวิตที่ดีขึ้น',
  'เรียนรู้เทคนิคจิตวิทยาใน 1 นาที',
  'เรียนรู้วิธีรักตัวเอง',
  'ทักษะการรอดชีวิตจากภัยพิบัติ',
  'วิธีอยู่ได้โดยไม่ติดมือถือ',
  'วิธีคิดที่ดีในการแก้ปัญหา',
  'พันธุกรรมกำหนดตัวฉันมากน้อยแค่ไหน',
  'เกมจิตวิทยาจะประสบความสำเร็จหรือไม่',
  'หัวใจของกลยุทธ์การขายคืออะไร',
  'คิดมากเกินไปอันตรายอย่างไร',
  'สมองของผู้ชายมีอะไรบ้าง',
  'ต้องการพูดให้กระชับ',
  'ขอบคุณสำหรับการโต้เถียง',
  'ล้างสมองแบรนด์',
  'กำลังใจของฉันกำลังสั่นคลอน',
  'หลุดพ้นจากอาการอิมโพสเตอร์ซินโดรม',
  'อย่าตกเป็นเหยื่อของความคิด',
  'เอาชนะโรคภูมิต้านทานผิดปกติ',
  'กลยุทธ์การจัดการตนเองเพื่อความสำเร็จสูง',
  'สมองของผู้หญิงน่าสนใจ',
  'วิธีบรรเทาความวิตกกังวล',
  'ใช้เวลาในหนึ่งวันให้มีประโยชน์',
  'ทำลายนิสัยเก่าและสร้างนิสัยใหม่',
  'กลยุทธ์ความสำเร็จของสตาร์ทอัพ',
  'ที่ไหนดีที่สุดที่จะอยู่',
  'อนาคตของอาชีพเฉพาะในยุค AI',
  'เคล็ดลับการดูแลตัวเองไม่ให้แก่',
  'ผลร้ายจากอาหารแปรรูป',
  'อย่าให้ความคิดมโนบงการร่างกาย',
  'วิธีขจัดความเครียด',
  'การควบคุมโรคทางจิต',
  'พลังของอารมณ์บวก',
  'การจัดการเวลาเพื่อความสำเร็จและความสงบ',
  'ความอยากรู้เกี่ยวกับภัยในอนาคต',
  'กลับสู่ชีวิตที่เรียบง่ายด้วยการจัดระเบียบ',
  'การทำสมาธิเพื่อสันติภาพในใจ',
  'การแก้ไขปัญหาการนอนไม่หลับ',
  'เคล็ดลับในการสร้างและรักษามิตรภาพ',
  'การรักษาเพื่อขจัดการคุกคามจากก๊าซไลต์',
  'กลยุทธ์หลุดพ้นจากผู้ชายไม่ดี',
  'การปรับปรุงผลงานด้วยจิตวิทยากีฬา',
  'วิธีการเป็นคนรวยที่ดีที่สุด',
  'อาหารพืชธรรมชาติดีจริงหรือไม่',
  'สิ่งที่ควรรู้เกี่ยวกับความตาย',
  'กลยุทธ์การขายที่ดึงดูดลูกค้า',
  'ความสำเร็จต้องเริ่มจากการรู้จักการเงิน',
  'คุณรู้จักประเภทบุคลิกของตนเองหรือไม่',
  'วิธีคิดที่ช่วยเพิ่มความคิดสร้างสรรค์',
  'ใช้ชีวิตอย่างมีสุขภาพดีเพื่อยืนยาว',
  'เพิ่มประสิทธิภาพการทำงานในที่ทำงาน',
  'เคล็ดลับการลงทุนจากพ่อรวย',
  'วิธีหลีกเลี่ยงความขัดแย้งทางอารมณ์',
  'ลองไดเอทอย่างถูกวิธี',
  'วิธีประสบความสำเร็จในการสร้างความสัมพันธ์ผ่านการสนทนา',
  'การระบุคนไซโคแพท',
  'มีวิธีการเรียนรู้ที่มีประสิทธิภาพหรือไม่',
  'อุปสรรคที่ขัดขวางการลงทุนที่ดี',
  'คุณติดอาหารหรือไม่',
  'ความลับของนวัตกรรมในซิลิคอนวัลเลย์',
  'การว่างงานที่เกิดจากปัญญาประดิษฐ์',
  'ทำไมคนทำงานหนักแต่ยังยากจน',
  'ความเข้าใจผิดเกี่ยวกับความสุข',
  'ใช้การเล่าเรื่องเพื่อโน้มน้าวใจ',
  'โลกหลังโควิดจะเป็นอย่างไร',
  'เคล็ดลับการออกแบบที่ดี',
  'วิธีดึงดูดโชคดี',
  'การต่อรองเพื่อผลประโยชน์',
  'เมื่อคู่รักประสบวิกฤต',
  'มารยาทอย่างไร',
  'ผลกระทบจากการเปลี่ยนแปลงของสภาพอากาศในอนาคต',
  'คำแนะนำสำหรับผู้นำที่กำลังกังวล',
  'เทคนิคการรอดชีวิตในที่ทำงาน',
  'คุณอยู่กับครอบครัวหรือไม่',
  'วิธีปลุกศักยภาพที่มีอยู่ในตัว',
  'ต้องการทราบเกี่ยวกับวิตามิน',
  'วิธีรับมือกับนาร์ซิสซิสต์อย่างมีปัญญา',
  'ประโยชน์ของการแต่งงานคืออะไร',
  'ประเภทความผูกพันของคุณคืออะไร',
  'พึ่งพาความรักของพระเจ้าดูสิ',
  'ความลับของสารที่ทำให้เกิดภาพหลอน',
  'อาหารที่ช่วยกำจัดอาการแพ้',
  'ทุนนิยมจะล่มสลายหรือ',
  'ความจริงเกี่ยวกับผู้ชายอัลฟ่า',
  'วิธีที่ดีที่สุดในการได้มาซึ่งอำนาจ',
  'วิธีร่ำรวยจากการประหยัด',
  'ควรรู้เรื่องนี้เกี่ยวกับผู้คน',
  'ผู้จัดการที่ดีมีลักษณะอย่างไร',
  'เพิ่มประสิทธิภาพการบรรลุเป้าหมายด้วยการมีสมาธิ',
  'ความคิดเรื่องเพศไม่หยุดหย่อน',
  'เทคนิคลับของผู้เชี่ยวชาญการตลาด',
  'ลงทุนด้วยการคิดแบบระบบ',
  'วิธีทำเงินจากสกุลเงินดิจิทัล',
  'ความจริงเกี่ยวกับโรคจิตที่แพร่หลายในยุคสมัยนี้',
  'ความจริงเรียบง่ายเกี่ยวกับการเป็นผู้นำ',
  'วิธีตรวจจับนักการเมืองประชานิยม',
  'รักษาจิตใจด้วยดนตรี',
  'สร้างผลิตภัณฑ์ที่ดีที่สุดได้อย่างไร',
  'วิธีเสริมสร้างพลังใจ',
  'ลักษณะของคนที่ล้มเหลว',
  'การหย่า มันง่ายหรือ',
  'ชามานิสึม, วิญญาณ, การฟื้นฟู',
  'ปัญหาจากความคิดบวกเกินไป',
  'ผู้ที่หมกมุ่นกับความสมบูรณ์แบบ',
  'ลักษณะเด่นของผู้มีอิทธิพล',
  'วิธีโจมตีตัวร้าย',
  'ถามผู้เชี่ยวชาญด้านการสื่อสาร',
  'ความรักทำอย่างไร',
  'บรรลุเป้าหมายด้วยการฟัง',
  'วิธีหาเงินอย่างไร',
  'เคล็ดลับในการเติบโตธุรกิจของคุณ',
  'ระวังคนที่โกงด้วยการใช้สถิติ',
  'เพิ่มพลังสมอง',
  'เข้าใจคนรุ่นมิลเลนเนียลที่ด้อยโอกาส',
  'ถ้ารู้จักรูปแบบ คุณสามารถเอาชนะเขาได้',
  'จะเอาชนะโรคซึมเศร้าได้หรือไม่',
  'ไม่สามารถหลุดพ้นจากการติดยา',
  'ใครมีแนวโน้มที่จะก่อความรุนแรง',
  'อย่าหลงเชื่อเรื่องไร้สาระ',
  'เคล็ดลับการเขียนประวัติย่อและการได้งาน',
  'ความเหงาทำลายฉันเล็กน้อยทุกวัน',
  'วิธีตั้งเป้าหมายและก้าวไปข้างหน้า',
  'คุณลองทำอดอาหารจริงๆ หรือยัง',
  'นิสัยลับของคนเจนิอุส',
  'วิธีหารายได้จากการเป็นฟรีแลนซ์',
  'เป็นเทพแห่งกลยุทธ์',
  'ไอเดียดีๆ มาจากไหน',
  'ความถ่อมตัวจะนำมาซึ่งความสำเร็จจริงหรือ',
  'โรคระบาดจะกลับมาอีกหรือ',
  'การตัดสินใจที่ไม่สมเหตุสมผล',
  'ผู้ที่เรียนรู้เป็นผู้ที่อยู่รอด',
  'แค่มีความสามารถก็สำเร็จได้หรือ',
  'ผู้ที่อยากรักจนไม่ไหว',
  'คุณสมบัติของผู้ชายที่มีเสน่ห์',
  'คุณคิดว่าสมองของคุณฉลาดหรือไม่',
  'การโกหกจะได้ผลจริงหรือ',
  'ผู้ที่ทำการตลาดได้ดีมีโอกาสชนะ',
  'วิธีทนทุกข์และเอาชนะ',
  'คุณรู้ไหมว่าใบหน้าบอกอะไร',
];
export const SearchSentencesDe = [
  'Geld mit Immobilien verdienen',
  'Einstieg in den Aktienmarkt',
  'eBook selbst veröffentlichen',
  'Kreativ Geschäfte machen',
  'Leitfaden für weibliche Führungskräfte',
  'Geheimnisvolle Kraft von Kräutern',
  'Unternehmen mit rechter Gehirnhälfte aufbauen',
  'Kunst, unbequeme Gespräche führen',
  'Geld mit Webtexten verdienen',
  'Überleben in unsicherer Wirtschaft',
  'Mangel an sozialer Empathie',
  'Emotionen beherrschen für besseres Leben',
  'Psychologische Techniken in einer Minute',
  'Selbstliebe lernen',
  'Katastrophe überleben',
  'Ohne Mobiltelefon leben',
  'Gutes Denken für Problemlösung',
  'Gene bestimmen Ihre Identität',
  'Gedankenspiele funktionieren',
  'Schlüssel zur Verkaufsstrategie',
  'Übermäßiges Denken ist schädlich',
  'Gehirn eines Mannes',
  'Sei prägnant',
  'Dankbar für die Debatte sein',
  'Gehirnwäsche für Ihre Marke',
  'Mentale Stärke schwankt',
  'Maskensyndrom überwinden',
  'Denkfallen vermeiden',
  'Autoimmunkrankheit überwinden',
  'Selbstfürsorgestrategien für Erfolg',
  'Interesse am weiblichen Gehirn',
  'Ängste abbauen',
  'Optimieren Sie Ihren Tag',
  'Gewohnheiten ändern und neue schaffen',
  'Erfolgreiches Startup gründen',
  'Bester Wohnort',
  'Zukunft der Arbeit im KI-Zeitalter',
  'Nie alt werden',
  'Schädlichkeit verarbeiteter Lebensmittel',
  'Wahnvorstellungen kontrollieren',
  'Stress abbauen',
  'Umgang mit psychischen Krankheiten',
  'Positive Emotionen nutzen',
  'Zeitmanagement für Erfolg und Ruhe',
  'Sorgen um zukünftige Bedrohungen',
  'Einfaches, minimalistisches Leben',
  'Innere Ruhe durch Meditation',
  'Umgang mit Schlafmangel',
  'Freunde finden und pflegen',
  'Gaslighting bekämpfen',
  'Ausbruch aus einer toxischen Beziehung',
  'Leistung durch Sportpsychologie steigern',
  'Reichtum anhäufen',
  'Ist Rohkost wirklich gesund?',
  'Wissenswertes über den Tod',
  'Kunden gewinnen mit Verkaufsstrategien',
  'Finanzen kennen für Erfolg',
  'Persönlichkeitstyp kennen',
  'Kreative Geisteshaltungen',
  'Langes, gesundes Leben',
  'Effektivität am Arbeitsplatz maximieren',
  'Investitionsgeheimnisse von Rich Dad',
  'Emotionale Konflikte vermeiden',
  'Richtige Ernährung',
  'Erfolgreiche Beziehungen durch Dialog',
  'Psychopathen erkennen',
  'Effektive Lernmethoden',
  'Hindernisse bei Investitionen',
  'Sind Sie essenssüchtig?',
  'Innovationsgeheimnis im Silicon Valley',
  'Arbeitslosigkeit durch KI',
  'Warum trotz harter Arbeit arm',
  'Missverständnisse über Glück',
  'Überzeugen durch Geschichten',
  'Welt nach COVID',
  'Geheimnis gutes Designs',
  'Glück anziehen',
  'Vorteilhaft verhandeln',
  'Krisenbewältigung in Beziehungen',
  'Drogenwirkung',
  'Zukünftige Auswirkungen des Klimawandels',
  'Leitfaden für Führungskräfte in Krisen',
  'Überlebenstipps am Arbeitsplatz',
  'Leben mit der Familie',
  'Talente pflegen',
  'Vitaminlösungen',
  'Intelligenter Umgang mit Narzissten',
  'Vorteile einer Ehe',
  'Bindungstyp erkennen',
  'Vertrauen auf Gottes Liebe',
  'Halluzinogene Substanzen',
  'Allergiebekämpfende Lebensmittel',
  'Kapitalismus und sein Untergang',
  'Die Realität von Alpha-Männchen',
  'Macht aufbauen',
  'Reichtum durch Genügsamkeit',
  'Wissenswertes über Menschen',
  'Merkmale eines guten Managers',
  'Leistung maximieren',
  'Sexuelle Gedankenkontrolle',
  'Kunst des Marketing',
  'Systematisches Investieren',
  'Geld verdienen mit Kryptowährungen',
  'Die Natur moderner Geisteskrankheiten',
  'Wahre Führungswerte',
  'Erkennen eines populistischen Politikers',
  'Heilende Kraft der Musik',
  'Produktentwicklung',
  'Willensstärke stärken',
  'Merkmal gescheiterter Menschen',
  'Scheidungskomplexität',
  'Schamanismus und Heilung',
  'Problem mit Affirmationen',
  'Erfolgsgewinnung',
  'Merkmal einflussreicher Personen',
  'Annäherung an Schurken',
  'Kommunikationsmeister befragen',
  'Liebe machen',
  'Zuhören für Erfolg',
  'Geld verdienen',
  'Geschäftsentwicklung',
  'Vorsicht vor Statistik-Tricks',
  'Gehirnleistung steigern',
  'Mitleid mit Millennials',
  'Rahmen kennen, besiegen',
  'Depressionen überwinden',
  'Sucht überwinden',
  'Gewalttätige Tendenzen erkennen',
  'Schwachsinn erkennen',
  'Lebenslauf schreiben und Jobsuche',
  'Einsamkeit überwinden',
  'Ziele setzen und vorankommen',
  'Fasten ausprobieren',
  'Geheime Genie-Gewohnheiten',
  'Freiberuflich Geld verdienen',
  'Strategie-Meister werden',
  'Große Ideen entwickeln',
  'Bescheidenheit und Erfolg',
  'Rückkehr der Pandemie',
  'Irrationale Entscheidungen treffen',
  'Überleben durch Lernen',
  'Talent und Erfolg',
  'Verzweifelt nach Liebe',
  'Attraktivität eines Mannes',
  'Gehirnintelligenz einschätzen',
  'Wirkung von Lügen',
  'Beste Werbung gewinnt',
  'Schmerzen ertragen und besiegen',
  'Gesichtsdeutung',
];
export const SearchSentencesFr = [
  "Gagner grâce à l'immobilier",
  'Plonger dans la bourse pour débutants',
  'Autopublier un e-book best-seller',
  "Gagner des marchés par l'innovation",
  'Conseils pour femmes dirigeantes',
  'Le pouvoir mystérieux des herbes',
  'Entreprise créative',
  'Maîtriser les conversations délicates',
  "Gagner de l'argent avec la rédaction web",
  'Survivre dans une économie incertaine',
  "Les personnes qui manquent d'empathie sociale",
  'Maîtriser ses émotions pour mieux vivre',
  'Techniques psy en une minute',
  "Apprendre à s'aimer soi-même",
  'Comment survivre à une catastrophe',
  'Comment vivre sans téléphone portable',
  'Comment bien penser pour résoudre les problèmes',
  'Gènes et identité',
  "Les jeux d'esprit fonctionnent-ils",
  "Quelle est la clé d'une stratégie de vente",
  'Trop penser est néfaste',
  "Que contient le cerveau d'un homme",
  'Il faut être concis',
  'Soyez reconnaissant pour le débat',
  'Laver le cerveau de votre marque',
  'Ma force mentale vacille',
  'Se libérer du syndrome du masque',
  'Éviter les pièges mentaux',
  'Vaincre la maladie auto-immune',
  'Stratégies santé, rendement haut',
  'Curieux du cerveau féminin',
  "Comment soulager l'anxiété",
  'Tirer le meilleur parti de votre journée',
  'Briser habitudes, créer nouvelles.',
  'Réussir une startup.',
  'Meilleur endroit pour vivre.',
  'Professionnalisme et IA.',
  'Secret pour ne jamais vieillir.',
  'Aliments transformés, si mauvais',
  'Ne laissez illusions prendre corps.',
  'Lâcher prise sur le stress.',
  'Gérer maladies mentales.',
  'Pouvoir des émotions positives.',
  'Gestion du temps pour calme.',
  'Interroger menaces futures.',
  'Vie désencombrement et vide.',
  'Trouver paix grâce méditation.',
  'Gérer manque de sommeil.',
  'Secret pour faire, entretenir amitiés.',
  'Prescription contre gaslighting.',
  'Stratégie pour obsession mauvais garçon.',
  'Améliorer performances sportives.',
  "Meilleure façon de s'enrichir.",
  'Régime crudivore, vraiment bon',
  "Ce qu'il faut savoir sur mort.",
  'Stratégies vente pour attirer clients.',
  'Connaître finances pour réussir.',
  'Connaissez-vous votre personnalité',
  "États d'esprit stimulant créativité.",
  'Vivre longtemps, en santé.',
  'Maximiser efficacité au travail.',
  'Secrets investissement de Rich Dad.',
  'Éviter conflits émotionnels.',
  'Suivre régime alimentaire approprié.',
  'Réussir relations par dialogue.',
  'Identifier psychopathe.',
  "Méthodes d'apprentissage efficaces",
  'Obstacles bons investissements.',
  'Accro à la nourriture',
  "Le secret de l'innovation dans la Silicon Valley",
  "Le chômage qu'entraînera l'intelligence artificielle",
  'Pourquoi est-on pauvre quand on travaille dur',
  'Idées fausses sur le bonheur',
  'Persuader avec le storytelling',
  'À quoi ressemblera le monde post-COVID',
  "Le secret d'une bonne conception",
  'Comment attirer la chance',
  'Négocier à votre avantage',
  'Quand les couples sont en crise',
  'À quoi ressemblent les drogues',
  'Quels sont les impacts futurs du changement climatique',
  'Lignes directrices pour les dirigeants en difficulté',
  'Conseils pour survivre au travail',
  "Vivez-vous en famille aujourd'hui",
  'Comment préserver vos talents',
  'La solution des vitamines',
  'Gérer narcissiques intelligemment',
  'Quels sont les avantages du mariage',
  "Quel est mon type d'attachement",
  "S'appuyer sur l'amour de Dieu",
  'Secrets hallucinogènes',
  'Les aliments qui combattent les allergies',
  'Le capitalisme est condamné',
  'La réalité des mâles alpha',
  "La meilleure façon d'acquérir du pouvoir",
  'Comment devenir riche grâce à la frugalité',
  'Vous devriez savoir cela sur les gens',
  'À quoi ressemble un grand manager',
  "Maximiser la réussite par l'immersion",
  "Je n'arrête pas de penser au sexe",
  "L'art secret du mastermind marketing",
  'Investir avec la pensée systémique',
  "Comment gagner de l'argent avec les crypto-monnaies",
  'La vraie nature des maladies mentales modernes',
  'Les vérités simples du leadership',
  'Comment repérer un politicien populiste',
  'Laisser la musique guérir mon cœur',
  'Comment créer le meilleur produit',
  'Le secret pour renforcer votre volonté',
  'Caractéristiques des personnes qui échouent',
  'Divorce est-ce facile',
  'Chamanisme, esprits et guérison',
  "Le problème de l'affirmation à outrance",
  'Comment devenir une personne qui réussit',
  'Caractéristiques des personnes influentes',
  'Comment aborder un méchant',
  'Demandez aux maîtres de la communication',
  "Comment faire l'amour",
  'Écoutez et vous y arriverez',
  "L'argent, comment le gagner",
  'Comment développer mon activité',
  'Méfiez-vous des tricheurs statistiques',
  'Augmentons notre puissance cérébrale',
  'Pitié pour le pauvre millénaire.',
  'Si vous connaissez le cadre, vous pouvez le battre',
  'Puis-je vaincre la dépression',
  "Je n'arrive pas à me débarrasser de ma dépendance",
  'Qui a des tendances violentes',
  'Comment ne pas se faire avoir',
  'Conseils CV et emploi',
  'La solitude me ronge petit à petit',
  "Comment se fixer des objectifs et aller de l'avant",
  "Le jeûne, vous ne l'avez vraiment jamais fait",
  'Les habitudes secrètes des génies',
  'Gagner en freelance',
  'Devenir un dieu de la stratégie',
  'Comment naissent les grandes idées',
  "L'humilité mène-t-elle vraiment au succès",
  'La pandémie revient-elle',
  'Je prends des décisions irrationnelles',
  'Celui qui apprend survit',
  'Le talent seul permet-il de réussir',
  'Ceux qui cherchent désespérément à être aimés',
  "Qu'est-ce qui fait un homme séduisant",
  'Pensez-vous que votre cerveau est intelligent',
  "Quelle est l'efficacité réelle d'un mensonge",
  'Celui qui fait la meilleure publicité gagne à la fin',
  "L'art d'endurer la douleur et de gagner",
  'Savez-vous ce que révèle le visage',
];
export const SearchSentencesIt = [
  'Guadagnare con immobili',
  'Mercato azionario per neofiti',
  'Autopubblicare un bestseller eBook',
  'Vincere con innovazione creativa',
  'Diventare leader donna',
  'Il potere misterioso delle erbe',
  'Business con il cervello destro',
  "L'arte delle conversazioni difficili",
  'Guadagnare con il web writing',
  'Sopravvivere in economia incerta',
  'Mancanza di empatia sociale',
  'Padroneggiare emozioni per benessere',
  'Tecniche psicologiche veloci',
  'Imparare ad amare sé stessi',
  'Sopravvivere a disastri',
  'Vivere senza cellulare',
  'Pensare bene per risolvere',
  'Influenza dei geni su di noi',
  'Funzionano i giochi mentali',
  'Chiave strategia di vendita',
  "Danni dell'eccesso di pensiero",
  "Cosa c'è nel cervello maschile",
  'Essere concisi',
  'Ringraziare per argomentazioni',
  'Lavaggio del cervello del brand',
  'Scossa mentale',
  'Liberarsi dalla sindrome della maschera',
  'Evitare trappole mentali',
  'Superare malattie autoimmuni',
  'Strategie di cura di sé efficaci',
  'Curiosità sul cervello femminile',
  "Come alleviare l'ansia",
  'Sfruttare al meglio la giornata',
  'Cambiare e creare abitudini',
  'Successo delle startup',
  'Miglior posto per vivere',
  'Il futuro delle professioni AI',
  'Rimanere giovani senza età',
  'Danni degli alimenti trasformati',
  'Non lasciare governare le illusioni',
  'Lasciare andare lo stress',
  'Affrontare la malattia mentale',
  'Il potere delle emozioni positive',
  'Gestione del tempo e serenità',
  'Interrogarsi sulle future minacce',
  'Ritorno a vita decluttered',
  'Pace mentale con meditazione',
  'Affrontare la mancanza di sonno',
  'Il segreto delle amicizie durature',
  'Ricetta contro il gaslighting',
  "Uscire dall'ossessione",
  'Prestazioni e psicologia sportiva',
  'Il miglior modo per arricchirsi',
  'Benefici di una dieta crudista',
  'Cosa sapere sulla morte',
  'Strategie di vendita efficaci',
  'Gestire finanze per successo',
  'Conoscere il proprio tipo di personalità',
  'Atteggiamenti che stimolano creatività',
  'Vivere una vita lunga e sana',
  'Massimizzare efficienza sul lavoro',
  'I segreti degli investimenti',
  'Evitare conflitti emotivi',
  'Come seguire una dieta corretta',
  'Successo relazionale tramite dialogo',
  'Identificare uno psicopatico',
  'Metodi di apprendimento efficaci',
  'Ostacoli agli investimenti corretti',
  'Dipendenza dal cibo',
  "Il segreto dell'innovazione della Silicon Valley",
  'Disoccupazione, IA',
  'Perché sei povero se lavori sodo',
  'Idee sbagliate sulla felicità',
  'Persuadere con lo storytelling',
  'Come sarà il mondo post-COVID',
  'Il segreto del buon design',
  'Come attirare la fortuna',
  'Negoziare a proprio vantaggio',
  'Quando le coppie sono in crisi',
  'Come sono le droghe',
  'Impatti futuro clima',
  'Linee guida per i leader in difficoltà',
  'Consigli per sopravvivere al lavoro',
  'Vivete con la vostra famiglia',
  'Come mantenere vivo il proprio talento',
  'La soluzione vitaminica',
  'Modi intelligenti per affrontare i narcisisti',
  'Quali sono i benefici del matrimonio',
  'Quel è il mio tipo di attaccamento',
  "Affidarsi all'amore di Dio",
  'I segreti delle sostanze che causano allucinazioni',
  'Cibi che combattono le allergie',
  'Il capitalismo è condannato',
  'La realtà dei maschi alfa',
  'Il modo migliore per ottenere il potere',
  'Come diventare ricchi con la frugalità',
  'Dovresti sapere questo sulle persone',
  'Che aspetto ha un grande manager',
  "Massimizzare i risultati attraverso l'immersione",
  'Non riesco a smettere di pensare al sesso',
  "L'arte segreta del marketing mastermind",
  'Investire con il pensiero sistemico',
  'Come fare soldi con le criptovalute',
  'La vera natura delle moderne malattie mentali',
  'Le semplici verità della leadership',
  'Come riconoscere un politico populista',
  'Lasciare che la musica guarisca il mio cuore',
  'Come creare il miglior prodotto',
  'Il segreto per rafforzare la forza di volontà',
  'Le caratteristiche delle persone che falliscono',
  'Divorziare è facile',
  'Sciamanesimo, spiriti e guarigione',
  'Il problema delle troppe affermazioni',
  'Come diventare una persona di successo',
  'Caratteristiche delle persone influenti',
  'Ecco come approcciare un cattivo',
  'Chiedi ai maestri della comunicazione',
  "Come si fa l'amore",
  'Ascoltate e otterrete',
  'Soldi, come farli',
  'Come far crescere il mio business',
  'Attenzione ai trucchi statistici',
  'Aumentiamo la nostra potenza cerebrale',
  'Pietà per il povero millennial.',
  'Se conosci il telaio, puoi batterlo',
  'Posso sconfiggere la depressione',
  'Non riesco a superare la mia dipendenza',
  'Chi ha tendenze violente',
  'Come non cadere nelle stronzate',
  'Consigli per la scrittura del CV e la ricerca di lavoro',
  'La solitudine mi mangia a poco a poco',
  'Come fissare gli obiettivi e andare avanti',
  "Digiuno, davvero non l'avete mai fatto",
  'Le abitudini segrete dei geni',
  'Diversi modi per fare soldi come freelance',
  'Diventare un dio della strategia',
  'Come nascono le grandi idee',
  "L'umiltà porta davvero al successo",
  'La pandemia sta arrivando di nuovo',
  'Prendo decisioni irrazionali',
  'Chi impara sopravvive',
  'Il talento da solo fa il successo',
  'Chi cerca disperatamente di essere amato',
  'Cosa rende un uomo attraente',
  'Pensi che il tuo cervello sia intelligente',
  'Quanto funziona davvero una bugia',
  'Alla fine vince chi fa la pubblicità migliore',
  "L'arte di sopportare il dolore e vincere",
  'Sai cosa rivela il viso',
];
export const SearchSentencesRu = [
  'Заработок на недвижимости',
  'Фондовый рынок для новичков',
  'Самостоятельно издайте книгу',
  'Бизнес с креативными инновациями',
  'Стать женщиной-лидером',
  'Таинственная сила трав',
  'Бизнес с правым мозгом',
  'Неудобные разговоры',
  'Заработок на веб-писании',
  'Выживание в неопределенной экономике',
  'Мало социальной эмпатии',
  'Мастерство эмоций',
  'Техники за минуту',
  'Любить себя',
  'Пережить катастрофу',
  'Жить без телефона',
  'Правильное мышление',
  'Гены и личность',
  'Игры разума',
  'Ключ к продажам',
  'Чрезмерное мышление',
  'Мозг мужчины',
  'Лаконичность',
  'Благодарность за дискуссию',
  'Промыть мозги бренду',
  'Устойчивость вопрос',
  'Избавиться от маски',
  'Избегать мысленных ловушек',
  'Преодоление аутоиммунных',
  'Стратегии самопомощи для успеха',
  'Любопытно о женском мозге',
  'Как снять тревогу',
  'Максимум пользы за день',
  'Отказ и создание привычек',
  'Как стать успешным стартапером',
  'Где лучше всего жить',
  'Будущее профессионализма в эпоху ИИ',
  'Секрет вечной молодости',
  'Вред переработанной пищи',
  'Иллюзии и контроль над телом',
  'Отпускаем стресс',
  'Справиться с псих. заболеваниями',
  'Сила положительных эмоций',
  'Управление временем для успеха',
  'Размышления о будущих угрозах',
  'Возвращение к жизни с порядком',
  'Душевный покой через медитацию',
  'Проблемы с недостатком сна',
  'Секреты дружбы и отношений',
  'Рецепт против газлайтинга',
  'Стратегия избежать плохих',
  'Улучшение с помощью спорт. психологии',
  'Лучший способ разбогатеть',
  'Польза сырой вег. диеты?',
  'Что знать о смерти',
  'Стратегии привлечения клиентов',
  'Знайте свои финансы для успеха',
  'Ваш тип личности',
  'Установки для креативности',
  'Долгая и здоровая жизнь',
  'Повышение эффективности на работе',
  'Секреты инвестирования',
  'Избегание эмоциональных конфликтов',
  'Правильное питание',
  'Успех в отношениях через диалог',
  'Определение психопата',
  'Методы эффективного обучения',
  'Препятствия в инвестициях',
  'Зависимость от еды',
  'Секрет инноваций Кремниевой долины',
  'Безработица от искусственного интеллекта',
  'Почему бедность при трудолюбии',
  'Заблуждения о счастье',
  'Убеждение через рассказы',
  'Мир после COVID',
  'Секрет хорошего дизайна',
  'Как привлечь удачу',
  'Переговоры с выгодой',
  'Кризис в отношениях',
  'Разновидности наркотиков',
  'Последствия изменения климата',
  'Рекомендации кризисным руководителям',
  'Советы по выживанию на работе',
  'Жизнь с семьей',
  'Сохранение талантов',
  'Витаминное решение',
  'Борьба с нарциссами',
  'Преимущества брака',
  'Тип вашей привязанности',
  'Полагаться на любовь Бога',
  'Секреты галлюциногенных веществ',
  'Продукты против аллергии',
  'Капитализм обречен',
  'Реальность альфа-самцов',
  'Лучший способ к власти',
  'Как разбогатеть через бережливость',
  'Доверия заслуживающие люди',
  'Как выглядит великий менеджер',
  'Максимизация достижений',
  'Мысли о сексе',
  'Секрет маркетингового мастерства',
  'Инвестиции и системное мышление',
  'Заработок на криптовалюте',
  'Природа психических заболеваний',
  'Простые истины лидерства',
  'Распознавание популиста',
  'Пусть музыка исцелит сердце',
  'Создание лучшего продукта',
  'Секрет силы воли',
  'Черты людей, терпящих неудачи',
  'Развод: легко ли это',
  'Шаманизм и выздоровление',
  'Проблема избытка аффирмаций',
  'Как стать успешным человеком',
  'Характеристики влиятельных людей',
  'Как найти подход к злодею',
  'Советы мастеров общения',
  'Как вы занимаетесь любовью',
  'Слушайте и достигните успеха',
  'Деньги: как их заработать',
  'Развитие своего бизнеса',
  'Осторожно: обман через статистику',
  'Увеличим мощность мозга',
  'Пожалейте бедного миллениала',
  'Знание рамок — путь к победе',
  'Могу ли я победить депрессию',
  'Преодоление зависимости',
  'Склонность к насилию',
  'Как избежать ловушки',
  'Советы по резюме и работе',
  'Одиночество съедает меня',
  'Как ставить и достигать цели',
  'Пост: неужели никогда',
  'Секретные привычки гениев',
  'Заработок для фрилансера',
  'Станьте богом стратегии',
  'Как рождаются великие идеи',
  'Смирение и успех',
  'Пандемия снова надвигается',
  'Иррациональные решения',
  'Учиться для выживания',
  'Талант и успех',
  'Отчаянное желание быть любимым',
  'Что делает мужчину привлекательным',
  'Ваш мозг: умный или нет?',
  'Эффективность лжи',
  'Кто делает лучшую рекламу',
  'Искусство терпеть боль',
  'Что говорит ваше лицо',
];
