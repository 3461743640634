import { createAction, handleActions } from 'redux-actions';
import produce from 'immer';
import { pender } from 'redux-pender';
import * as publicAPI from '../../network/api/publicApi';
import * as privateAPI from '../../network/api/privateApi';
import SearchSentences, { SearchSentencesEn, SearchSentencesZh, SearchSentencesJa, SearchSentencesEs,
  SearchSentencesVi, SearchSentencesId, SearchSentencesTh, SearchSentencesDe, SearchSentencesFr,
  SearchSentencesIt, SearchSentencesRu } from '../../components/pages/HomePage/SearchSentences';
import storage from '../../lib/storage';

// Action Types
const GET_MOSTVIEWED_CELLS = 'randomCells/GET_MOSTVIEWED_CELLS';
const GET_HOT_COLLECTIONS = 'randomCells/GET_HOT_COLLECTIONS';
const GET_NEW_CELLS = 'randomCells/GET_NEW_CELLS';
const GET_HISTORY_CELLS = 'randomCells/GET_HISTORY_CELLS';
const GET_RANDOM_SENTENCES = 'randomCells/GET_RANDOM_SENTENCES';
const GET_RANDOM_KEYWORDS = 'randomCells/GET_RANDOM_KEYWORDS';

// Action Creator
export const getMostViewedCells = createAction(GET_MOSTVIEWED_CELLS, publicAPI.getMostViewedCells);
export const getHotCollections = createAction(GET_HOT_COLLECTIONS, publicAPI.getCollectionsByCategory);
export const getNewCells = createAction(GET_NEW_CELLS, publicAPI.getCollectionsByCategory);
export const getHistoryCells = createAction(GET_HISTORY_CELLS, privateAPI.getCellHistory);
export const getRandomSentences = createAction(GET_RANDOM_SENTENCES);
export const getRandomKeywords = createAction(GET_RANDOM_KEYWORDS, publicAPI.getRandomKeywords, (...meta) => meta);

// Initial State
const initialState = {
  mostViewedCells: [],
  hotCollections: [],
  newCells: [],
  historyCells: {
    historyCells: [],
    relatedCells: [],
  },
  randomSentences: [],
  randomKeywordsCounts: [{ keyword: 'Loading...', count: 1 }],
  randomReferenceTitleCounts: [{ referenceTitle: 'Loading...', count: 1 }],
  randomReferenceAuthorCounts: [{ referenceAuthor: 'Loading...', count: 1 }],
  randomImageURLs: [],
};

// Reducers
export default handleActions(
  {
    ...pender({
      type: GET_MOSTVIEWED_CELLS,
      onSuccess: (state, action) => produce(state, (draft) => {
        draft.mostViewedCells = action.payload.data.cells.sort(() => Math.random() - 0.5);
      }),
    }),
    ...pender({
      type: GET_HOT_COLLECTIONS,
      onSuccess: (state, action) => produce(state, (draft) => {
        draft.hotCollections = action.payload.data.cells.sort(() => Math.random() - 0.5);
      }),
    }),
    ...pender({
      type: GET_NEW_CELLS,
      onSuccess: (state, action) => {
        const { cells } = action.payload.data;
        return produce(state, (draft) => {
          draft.newCells = [];
          cells.map((item) => {
            const latestCells = [...item.cells].reverse();
            if (item.cells.length > 0) {
              for (const cell of latestCells) {
                draft.newCells.push({ ...cell, cid: item.id });
              }
            }
            return draft;
          });
        });
      },
    }),
    ...pender({
      type: GET_HISTORY_CELLS,
      onSuccess: (state, action) => produce(state, (draft) => {
        draft.historyCells.historyCells = action.payload.data.cells;
      }),
    }),
    [GET_RANDOM_SENTENCES]: (state, action) => produce(state, (draft) => {
      const userLanguage = storage.get('userLanguage') || navigator.language.split('-')[0];

      const randomizeArray = (array) => array.slice().sort(() => Math.random() - 0.5);

      // 'ko', 'en', 'zh' 언어 코드에 따라 다른 배열을 선택하여 무작위로 섞는 함수
      const getRdSentences = () => {
        let randomizedArray;
        switch (userLanguage) {
          case 'ko':
            randomizedArray = randomizeArray(SearchSentences);
            break;
          case 'en':
            randomizedArray = randomizeArray(SearchSentencesEn);
            break;
          case 'zh':
            randomizedArray = randomizeArray(SearchSentencesZh);
            break;
          case 'ja':
            randomizedArray = randomizeArray(SearchSentencesJa);
            break;
          case 'es':
            randomizedArray = randomizeArray(SearchSentencesEs);
            break;
          case 'vi':
            randomizedArray = randomizeArray(SearchSentencesVi);
            break;
          case 'id':
            randomizedArray = randomizeArray(SearchSentencesId);
            break;
          case 'th':
            randomizedArray = randomizeArray(SearchSentencesTh);
            break;
          case 'de':
            randomizedArray = randomizeArray(SearchSentencesDe);
            break;
          case 'fr':
            randomizedArray = randomizeArray(SearchSentencesFr);
            break;
          case 'it':
            randomizedArray = randomizeArray(SearchSentencesIt);
            break;
          case 'ru':
            randomizedArray = randomizeArray(SearchSentencesRu);
            break;
          default:
            randomizedArray = randomizeArray(SearchSentences);
        }
        return randomizedArray;
      };
      draft.randomSentences = getRdSentences(userLanguage);
    }),
    ...pender({
      type: GET_RANDOM_KEYWORDS,
      onSuccess: (state, action) => produce(state, (draft) => {
        const searchOption = action.meta[0];
        if (searchOption === 'keywords') {
          draft.randomKeywordsCounts = action.payload.data;
        } else if (searchOption === 'referenceAuthor') {
          draft.randomReferenceAuthorCounts = action.payload.data;
        } else if (searchOption === 'referenceTitle') {
          draft.randomReferenceTitleCounts = action.payload.data;
        } else if (searchOption === 'imageURL') {
          draft.randomImageURLs = action.payload.data;
        }
        return draft;
      }),
    }),
  },
  initialState,
);
