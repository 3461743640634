import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { NavLink, useLocation, Link, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash.get';
import DOMPurify from 'isomorphic-dompurify';
import { flexCenter } from '../../../utils/mixins';
import MediaView from '../../containers/MediaView';
import storage from '../../../lib/storage';
import { getTodayCollections } from '../../../store/reducers/collection';
import translations from '../../../utils/translations';
import imgPage from '../../../static/image/menu_cell.svg';
import imgPageEn from '../../../static/image/menu_page_en.svg';
import imgPageZh from '../../../static/image/menu_page_zh.svg';
import imgPageJa from '../../../static/image/menu_page_ja.svg';
import imgPageDe from '../../../static/image/menu_page_de.svg';
import imgPageIt from '../../../static/image/menu_page_it.svg';
import imgPageVi from '../../../static/image/menu_page_vi.svg';
import imgPageId from '../../../static/image/menu_page_id.svg';
import imgPageTh from '../../../static/image/menu_page_th.svg';
import imgPageRu from '../../../static/image/menu_page_ru.svg';
import imgPageEs from '../../../static/image/menu_page_es.svg';

import imgPageActive from '../../../static/image/menu_cell_active.svg';
import imgPageActiveEn from '../../../static/image/menu_page_en_active.svg';
import imgPageActiveZh from '../../../static/image/menu_page_zh_active.svg';
import imgPageActiveJa from '../../../static/image/menu_page_ja_active.svg';
import imgPageActiveDe from '../../../static/image/menu_page_de_active.svg';
import imgPageActiveIt from '../../../static/image/menu_page_it_active.svg';
import imgPageActiveVi from '../../../static/image/menu_page_vi_active.svg';
import imgPageActiveId from '../../../static/image/menu_page_id_active.svg';
import imgPageActiveTh from '../../../static/image/menu_page_th_active.svg';
import imgPageActiveRu from '../../../static/image/menu_page_ru_active.svg';
import imgPageActiveEs from '../../../static/image/menu_page_es_active.svg';


import imgBook from '../../../static/image/menu_knowledgebook.svg';
import imgBookEn from '../../../static/image/menu_minibook_en.svg';
import imgBookZh from '../../../static/image/menu_minibook_zh.svg';
import imgBookJa from '../../../static/image/menu_minibook_ja.svg';
import imgBookFr from '../../../static/image/menu_minibook_fr.svg';
import imgBookIt from '../../../static/image/menu_minibook_it.svg';
import imgBookVi from '../../../static/image/menu_minibook_vi.svg';
import imgBookId from '../../../static/image/menu_minibook_id.svg';
import imgBookTh from '../../../static/image/menu_minibook_th.svg';
import imgBookRu from '../../../static/image/menu_minibook_ru.svg';
import imgBookEs from '../../../static/image/menu_minibook_es.svg';

import imgBookActive from '../../../static/image/menu_knowledgebook_active.svg';
import imgBookActiveEn from '../../../static/image/menu_minibook_en_active.svg';
import imgBookActiveZh from '../../../static/image/menu_minibook_zh_active.svg';
import imgBookActiveJa from '../../../static/image/menu_minibook_ja_active.svg';
import imgBookActiveFr from '../../../static/image/menu_minibook_fr_active.svg';
import imgBookActiveIt from '../../../static/image/menu_minibook_it_active.svg';
import imgBookActiveVi from '../../../static/image/menu_minibook_vi_active.svg';
import imgBookActiveId from '../../../static/image/menu_minibook_id_active.svg';
import imgBookActiveTh from '../../../static/image/menu_minibook_th_active.svg';
import imgBookActiveRu from '../../../static/image/menu_minibook_ru_active.svg';
import imgBookActiveEs from '../../../static/image/menu_minibook_es_active.svg';


import imgMemo from '../../../static/image/menu_memo.svg';
import imgMemoEn from '../../../static/image/menu_memo_en.svg';
import imgMemoZh from '../../../static/image/menu_memo_zh.svg';
import imgMemoJa from '../../../static/image/menu_memo_ja.svg';
import imgMemoFr from '../../../static/image/menu_memo_fr.svg';
import imgMemoDe from '../../../static/image/menu_memo_de.svg';
import imgMemoVi from '../../../static/image/menu_memo_vi.svg';
import imgMemoTh from '../../../static/image/menu_memo_th.svg';
import imgMemoRu from '../../../static/image/menu_memo_ru.svg';

import imgMemoActive from '../../../static/image/menu_memo_active.svg';
import imgMemoActiveEn from '../../../static/image/menu_memo_en_active.svg';
import imgMemoActiveZh from '../../../static/image/menu_memo_zh_active.svg';
import imgMemoActiveJa from '../../../static/image/menu_memo_ja_active.svg';
import imgMemoActiveFr from '../../../static/image/menu_memo_fr_active.svg';
import imgMemoActiveDe from '../../../static/image/menu_memo_de_active.svg';
import imgMemoActiveVi from '../../../static/image/menu_memo_vi_active.svg';
import imgMemoActiveTh from '../../../static/image/menu_memo_th_active.svg';
import imgMemoActiveRu from '../../../static/image/menu_memo_ru_active.svg';

import imgTogether from '../../../static/image/menu_talk.svg';
import imgTogetherEn from '../../../static/image/menu_together_en.svg';
import imgTogetherZh from '../../../static/image/menu_together_zh.svg';
import imgTogetherJa from '../../../static/image/menu_together_en.svg';
import imgTogetherFr from '../../../static/image/menu_together_fr.svg';
import imgTogetherDe from '../../../static/image/menu_together_de.svg';
import imgTogetherIt from '../../../static/image/menu_together_it.svg';
import imgTogetherVi from '../../../static/image/menu_together_vi.svg';
import imgTogetherId from '../../../static/image/menu_together_id.svg';
import imgTogetherTh from '../../../static/image/menu_together_th.svg';
import imgTogetherRu from '../../../static/image/menu_together_ru.svg';
import imgTogetherEs from '../../../static/image/menu_together_es.svg';

import imgTogetherActive from '../../../static/image/menu_talk_active.svg';
import imgTogetherActiveEn from '../../../static/image/menu_together_en_active.svg';
import imgTogetherActiveZh from '../../../static/image/menu_together_zh_active.svg';
import imgTogetherActiveJa from '../../../static/image/menu_together_en_active.svg';
import imgTogetherActiveFr from '../../../static/image/menu_together_fr_active.svg';
import imgTogetherActiveDe from '../../../static/image/menu_together_de_active.svg';
import imgTogetherActiveIt from '../../../static/image/menu_together_it_active.svg';
import imgTogetherActiveVi from '../../../static/image/menu_together_vi_active.svg';
import imgTogetherActiveId from '../../../static/image/menu_together_id_active.svg';
import imgTogetherActiveTh from '../../../static/image/menu_together_th_active.svg';
import imgTogetherActiveRu from '../../../static/image/menu_together_ru_active.svg';
import imgTogetherActiveEs from '../../../static/image/menu_together_es_active.svg';


import imgYouBooker from '../../../static/image/menu_youbooker.svg';
import imgYouBookerActive from '../../../static/image/menu_youbooker_active.svg';

import { initHomeGraph } from '../../../store/reducers/searchResult';

const Wrapper = styled.div`
  width: 100%;
  position: absolute;
`;

const StyledMenu = styled.div`
  width: 100%;
  height: 70px;
  border-radius: 50px 50px 0 0;
  position: fixed;
  bottom: 0;
  background: white;
  box-shadow: 0 -2px 4px rgb(0 0 0 / 5%);
  z-index: 99;
  ${flexCenter};
  .menu{
    color: #a0a4a8;
  }
  .menu.active{
    color: #6d9eeb;
    @media (min-width: 640px) {
      background: #f4f4f4;
    }
  }
  
  .inner-block{
    width: 100%;
    display: flex;
  }
  @media (min-width: 640px) {
    height: 80px;
    position: absolute;
    .inner-block{
      width: 414px;
      height: 80px;
    }
  }
`;

const MenuLink = styled(NavLink)`
  width: 20%;
  position: relative;
  margin: 0 auto;
  font-size: 8px;
  ${flexCenter}
  flex-direction: column;
  span{
    margin-top: -2px;
  }
  @media (min-width: 640px) {
    width: 83px;
    :hover{
      background: #f4f4f4;
    }
  }
`;

const Footer = styled.div`
  width: 100%;
  height: 330px;
  background-color: rgba(109, 158, 235, 0.15);
  font-weight: 500;
  ${flexCenter}
  .logo{
    width: 290px;
    height: 184px;
    padding-top: 45px;
    border-right: 0.5px solid  rgba(255, 255, 255, 0.5);
  }
  .text-block{
    font-size: 14px;
    letter-spacing: -0.5px;
    width: 500px;
    padding-left: 56px;
    .divider{padding: 0 15px;}
    .title{margin-right: 39px;font-size: 12px;font-weight: 500;}
    .text1{padding-bottom: 20px;}
    .contact1{margin-bottom: 15px;line-height: 20px;}
    .contact2{display: inline-flex;margin-bottom: 15px;}
  }
  .span{margin-left: 60px;}
`;

const CopyRight = styled.div`
  width: 100%;
  height: 45px;
  background: black;
  color: white;
  font-size: 12px;
  ${flexCenter};
  font-weight: 300;
`;

const FooterMenu = () => {
  const dispatch = useDispatch();
  const [pathName, setPathName] = useState('/');
  const location = useLocation();
  const history = useHistory();
  const userLanguage = useSelector((state) => (get(state, ['auth', 'userLanguage'])));

  useEffect(() => {
    setPathName(location.pathname);
  }, [location]);

  const isActive = (to) => (match, tmpLocation) => tmpLocation.pathname.includes(to);

  const initScrollPosition = () => {
    storage.set('lastScrollPosition', 0);
    window.scrollTo(0, 0);
    // TO DO: 추후에 다른 방법 찾아볼 것
    document.body.style.overflow = 'auto'; // 메뉴 이동시 팔로워/팔로잉에서 페이지 고정되는 문제로 수정
    dispatch(getTodayCollections(20)); // 메뉴 이동시 오늘의 미니북 새로고침
  };

  if (window.isNativeApp) {
    return null;
  }

  return (
    <Wrapper>
      <MediaView maxWidth={639}>
        <StyledMenu>
          <div className="inner-block">
            {/* <MenuLink to="/home" exact className="menu" activeClassName="active" isActive={() => ['/home'].includes(pathName)}>
              <img src={pathName === '/home' ? imgIntroActive : imgIntro} alt="home" />
            </MenuLink> */}
            <MenuLink onClick={() => { dispatch(initHomeGraph()); initScrollPosition(); }} to="/home/cell" exact className="menu" activeClassName="active" isActive={() => ['/home/cell', '/search', '/search/result'].includes(pathName)}>
              <img
                src={(pathName === '/producer/mentors' || pathName.indexOf('/neuron/collection') === 0 || pathName.indexOf('/dailyIssue') === 0
                  || pathName.indexOf('/search/result') === 0 || pathName.indexOf('/home/cell') === 0)
                  ? userLanguage === 'ko' ? imgPageActive : userLanguage === 'en' ? imgPageActiveEn : userLanguage === 'zh' ? imgPageActiveZh : userLanguage === 'ja' ? imgPageActiveJa : userLanguage === 'de' ? imgPageActiveDe : userLanguage === 'it' ? imgPageActiveIt : userLanguage === 'vi' ? imgPageActiveVi : userLanguage === 'id' ? imgPageActiveId : userLanguage === 'th' ? imgPageActiveTh : userLanguage === 'ru' ? imgPageActiveRu : userLanguage === 'es' ? imgPageActiveEs : imgPageActiveEn : userLanguage === 'ko' ? imgPage : userLanguage === 'en' ? imgPageEn : userLanguage === 'zh' ? imgPageZh : userLanguage === 'ja' ? imgPageJa : userLanguage === 'de' ? imgPageDe : userLanguage === 'it' ? imgPageIt : userLanguage === 'vi' ? imgPageVi : userLanguage === 'id' ? imgPageId : userLanguage === 'th' ? imgPageTh : userLanguage === 'ru' ? imgPageRu : userLanguage === 'es' ? imgPageEs : imgPageEn}
                alt="search"
              />
            </MenuLink>
            <MenuLink to="/" exact onClick={() => { initScrollPosition(); }} className="menu" activeClassName="active">
              <img
                src={(pathName.indexOf('/producer') === 0 && pathName !== '/producer/mentors' && pathName.indexOf('/myProfile') === 0)
                  || pathName.indexOf('/collection') === 0 || pathName === '/'
                  || pathName.indexOf('/home/collection') === 0
                  ? userLanguage === 'ko' ? imgBookActive : userLanguage === 'en' ? imgBookActiveEn : userLanguage === 'zh' ? imgBookActiveZh : userLanguage === 'ja' ? imgBookActiveJa : userLanguage === 'fr' ? imgBookActiveFr : userLanguage === 'it' ? imgBookActiveIt : userLanguage === 'fr' ? imgBookActiveFr : userLanguage === 'vi' ? imgBookActiveVi : userLanguage === 'id' ? imgBookActiveId : userLanguage === 'th' ? imgBookActiveTh : userLanguage === 'ru' ? imgBookActiveRu : userLanguage === 'es' ? imgBookActiveEs : imgBookActiveEn : userLanguage === 'ko' ? imgBook : userLanguage === 'en' ? imgBookEn : userLanguage === 'zh' ? imgBookZh : userLanguage === 'ja' ? imgBookJa : userLanguage === 'fr' ? imgBookFr : userLanguage === 'it' ? imgBookIt : userLanguage === 'vi' ? imgBookVi : userLanguage === 'id' ? imgBookId : userLanguage === 'th' ? imgBookTh : userLanguage === 'ru' ? imgBookRu : userLanguage === 'es' ? imgBookEs : imgBookEn}
                alt="intelz"
              />
            </MenuLink>
            <MenuLink to="/memo/public" onClick={initScrollPosition} className="menu" activeClassName="active" isActive={() => ['/memo'].includes(pathName)}>
              <img
                src={(pathName === '/memo/public' || pathName.indexOf('/memo/public') === 0)
                  ? userLanguage === 'ko' ? imgMemoActive : userLanguage === 'en' ? imgMemoActiveEn : userLanguage === 'zh' ? imgMemoActiveZh : userLanguage === 'ja' ? imgMemoActiveJa : userLanguage === 'fr' ? imgMemoActiveFr : userLanguage === 'de' ? imgMemoActiveDe : userLanguage === 'vi' ? imgMemoActiveVi : userLanguage === 'th' ? imgMemoActiveTh : userLanguage === 'ru' ? imgMemoActiveRu : imgMemoActiveEn : userLanguage === 'ko' ? imgMemo : userLanguage === 'en' ? imgMemoEn : userLanguage === 'zh' ? imgMemoZh : userLanguage === 'ja' ? imgMemoJa : userLanguage === 'fr' ? imgMemoFr : userLanguage === 'de' ? imgMemoDe : userLanguage === 'vi' ? imgMemoVi : userLanguage === 'th' ? imgMemoTh : userLanguage === 'ru' ? imgMemoRu : imgMemoEn}
                alt="memo"
              />
            </MenuLink>

            <MenuLink to="/mentoring" onClick={initScrollPosition} className="menu" activeClassName="active" isActive={() => ['/mentoring', '/suggested', '/scheduled'].includes(pathName)}>
              <img
                src={(pathName === '/mentoring' || pathName.indexOf('/scheduled') === 0 || pathName.indexOf('/suggested') === 0)
                  ? userLanguage === 'ko' ? imgTogetherActive : userLanguage === 'en' ? imgTogetherActiveEn : userLanguage === 'zh' ? imgTogetherActiveZh : userLanguage === 'ja' ? imgTogetherActiveJa : userLanguage === 'fr' ? imgTogetherActiveFr : userLanguage === 'de' ? imgTogetherActiveDe : userLanguage === 'it' ? imgTogetherActiveIt : userLanguage === 'vi' ? imgTogetherActiveVi : userLanguage === 'id' ? imgTogetherActiveId : userLanguage === 'th' ? imgTogetherActiveTh :userLanguage === 'ru' ? imgTogetherActiveRu : userLanguage === 'es' ? imgTogetherActiveEs : imgTogetherActiveEn : userLanguage === 'ko' ? imgTogether : userLanguage === 'en' ? imgTogetherEn : userLanguage === 'zh' ? imgTogetherZh : userLanguage === 'ja' ? imgTogetherJa : userLanguage === 'fr' ? imgTogetherFr : userLanguage === 'de' ? imgTogetherDe : userLanguage === 'it' ? imgTogetherIt : userLanguage === 'vi' ? imgTogetherVi : userLanguage === 'id' ? imgTogetherId : userLanguage === 'th' ? imgTogetherTh : userLanguage === 'ru' ? imgTogetherRu : userLanguage === 'es' ? imgTogetherEs : imgTogetherEn}
                alt="mentoring"
              />
            </MenuLink>
            <MenuLink to="/youbooker" onClick={initScrollPosition} className="menu" activeClassName="active" isActive={() => ['/youbooker'].includes(pathName)}>
              <img
                src={pathName === '/youbooker' ? imgYouBookerActive : imgYouBooker}
                alt="youbooker"
              />
            </MenuLink>
          </div>
        </StyledMenu>
      </MediaView>
      <MediaView minWidth={640}>
        <Footer>
          <div className="text-block">
            <div className="text1">{translations[userLanguage].home_book_company_info}</div>
            <div>
              <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(translations[userLanguage].home_page_about_detail) }} />
              <br /><br />
              <span
                onClick={() => {               
                  window.open(userLanguage === 'zh'
                    ? 'https://youbook.blog/zh-hans/2024/04/terms-of-service/'
                    : `https://youbook.blog/${userLanguage}/2024/04/terms-of-service/`, '_blank');
                }}
                style={{ cursor: 'pointer' }}
              >{translations[userLanguage].terms_title}
              </span>
              <span className="divider">|</span>
              <span
                onClick={() => {
                  window.open(userLanguage === 'zh'
                    ? 'https://youbook.blog/zh-hans/2024/04/privacy-policy/'
                    : `https://youbook.blog/${userLanguage}/2024/04/privacy-policy/`, '_blank');
                }}
                style={{ cursor: 'pointer' }}
              >{translations[userLanguage].privacy_title}
              </span>
            </div>
          </div>
        </Footer>
        <CopyRight>
          Copyright © 2022 NICK syndicate. All rights reserved.
        </CopyRight>
      </MediaView>
    </Wrapper>
  );
};

export default FooterMenu;
